<script lang="ts" setup>
import { IconSecurityShieldCross } from '@cypress-design/vue-icon'
import { computed } from 'vue'
import NavigationTabs, { type TabDefinition } from './NavigationTabs.vue'

const props = defineProps<{
  currentTab: string
}>()

const tabs = computed(() => {
  const tabs = [
    {
      label: 'Privacy Policy',
      href: '/privacy-policy',
    },
    {
      label: 'Security & Compliance',
      href: '/security',
    },
    {
      label: 'Terms of Use',
      href: '/terms-of-use',
    },
    {
      label: 'Data Protection Addendum',
      href: '/data-protection-addendum',
    },
    {
      label: 'Sub-processors',
      href: '/subprocessors',
    },
  ] as TabDefinition[]
  const activeTab = tabs.find((tab) => tab.href === `/${props.currentTab}`)
  if (activeTab) {
    activeTab.active = true
  }
  return tabs
})
</script>

<template>
  <NavigationTabs :tabs="tabs" title="Trust Center">
    <template #icon>
      <IconSecurityShieldCross stroke-color="teal-500" fill-color="jade-200" />
    </template>
  </NavigationTabs>
</template>
